<template>
  <div>
    <page-title :heading="$t('erp.lang_specialOffers')"
                :permissionAdd="true" show-add-button
                url-add="/erp/baseData/specialOffer/add" :subheading="$t('erp.lang_specialOffers')" :icon="icon"></page-title>
    <div class="app-main__inner">
      <SpecialOffersComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import '@mdi/font/css/materialdesignicons.min.css'
import SpecialOffersComponent from "@/components/erp/baseData/specialoffers/SpecialOffersComponent.vue";

export default {
  components: {
    PageTitle,
    SpecialOffersComponent
  },
  data: () => ({
    icon: 'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
  }),
  computed:{
    hybrid(){ return process.env.VUE_APP_HYBRID == 'true' || this.$store.getters["permissions/checkHybridApp"]},
  }
}
</script>